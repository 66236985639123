.map-controls-container {
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 316px;
    height: 45px;
    border-bottom-right-radius: 25px;
    border-bottom-left-radius: 25px;
    border: 4px solid #FECC00;
    padding: 0 9px;
    background-color: black;
    left: 50%;
    transform: translateX(-50%);
}

.map-controls-select-location-bar-container {
    position: relative;
}

.map-controls-select-map-style-bar-container {
    position: relative;
}