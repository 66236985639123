.select-location-dropdown-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 158px;
  height: 45px;
  border-bottom-right-radius: 25px;
  border: 4px solid #FECC00;
  border-left: none;
  padding: 0 9px;
  background-color: black;
}

.select-location-dropdown-wrapper-name {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: none;
  width: 100%;
  height: 100%;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  color: white;
  user-select: none;
}

.select-location-dropdown-wrapper svg {
  width: 24px;
  height: 24px;
  margin-right: 10px;
  padding-right: 0;
  user-select: none;
  color: white;
}


