.select-map-style-results-list {
  position: absolute;
  top: calc(100%);
  z-index: 1;
  background-color: black;
  display: flex;
  flex-direction: column;
  border-radius: 25px;
  border: 3px solid #FECC00;
  margin: 5px;
  padding: 6px;
  width: 100%;
  min-width: 120px;
  max-height: 133px;
  overflow-y: auto;
  left: -5px;
}

.select-map-style-results-list::-webkit-scrollbar {
  width: 10px;
}

.select-map-style-results-list::-webkit-scrollbar-track {
  background-color: #e4e4e4;
  border-radius: 100px;
  margin: 15px;
}

.select-map-style-results-list::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 100px;
}
