.select-location-result {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 1px 10px 1px 10px;
  margin: 1px;
  color: #757575;
  border-radius: 10px;
  cursor: pointer;
  user-select: none;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
}

.select-location-result svg {
  width: 24px;
  height: 24px;
  margin-right: 10px;
  padding-right: 0;
  user-select: none;
  color: white;
}

.select-location-result.active svg {
  color: #FECC00;
}


.select-location-result.inactive svg {
  color: white;
}

.select-location-result.inactive:hover svg {
  color: #FECC00;
}

.select-location-result.active{
  background-color: black;
  color: #FECC00;
}

.select-location-result.inactive{
  background-color: black;
  color: white;
}

.select-location-result.inactive:hover{
  background-color: black;
  color: #FECC00;
}