.map-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  min-width: 230px;
}

.mapboxgl-ctrl-attrib {
  display: none;
}

a.mapboxgl-ctrl-logo {
  display: none;
}

.map-marker {
  display: block;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  padding: 0;
  width: 60px;
  height: 60px;
  background-size: cover;
  z-index: auto;
  opacity: 0.2;
}

.mapboxgl-popup-tip {
  border: 10px solid transparent;
}

.mapboxgl-popup-anchor-top .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip {
  border-bottom-color: #FECC00;
  align-self: center;
}

.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip {
  border-top-color: #FECC00;
  align-self: center;
}

.mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
  border-right-color: #FECC00;
  align-self: center;
}

.mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
  border-left-color: #FECC00;
  align-self: center;
}

.mapboxgl-popup.mapboxgl-popup-anchor-bottom {
  max-width: 400px;
}

.mapboxgl-popup-content {
  display: flex;
  background-color: transparent;
  border: none;
  padding: 0;
}

.popup-container {
  flex: 1;
  width: 100%;
  min-width: 210px;
  display: flex;
  flex-direction: column;
  background-color: black;
  border-radius: 25px;
  border: 4px solid #FECC00;
  justify-content: flex-start;
  font-size: 15px;
  text-align: left;
  padding: 6px;
}

.vehicle-id-container {
  display: flex;
  width: 100%;
  align-self: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  color: white;
  text-align: center;
  margin: 5px;
}

.popup-content-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-self: center;
  width: 100%;
  color: white;
  background-color: white;
  border-radius: 15px;
  margin: 1px;
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
}

.battery-container,
.last-location-container{
  display: flex;
  width: 100%;
  align-self: center;
  color: white;
  background-color: white;
  flex-direction: row;
  justify-content: flex-start;
  border-radius: 15px 15px 15px 15px;
  padding: 4px 8px 4px 8px;
  gap: 6px;
}

.battery-value,
.last-location-value {
  display: flex;
  align-self: center;
  align-content: center;
  color: black;
  background-color: transparent;
}

.battery-img-value,
.last-location-img-value {
  display: flex;
  align-self: center;
  align-content: center;
  border-radius: 8px;
  width: 24px;
  height: 24px;
  background-size: cover;
}

.battery-container {
  margin-top: 5px;
}

.last-location-link {
  align-self: center;
  align-content: center;
  color: #FECC00;
  text-decoration: none;
}

.start-ride-btn-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-self: center;
  align-content: center;
  color: black;
  text-decoration: none;
  width: 106px;
  height: 28px;
  background-color: #FECC00;
  border-radius: 23px;
  margin: 10px;
  font-size: 11px;
  gap: 5px;
}

@media (hover: hover) {
  .start-ride-btn-container:hover {
    box-shadow: 0px 0px 10px 0px #FECC00;
  }
}

@media (hover: none) {
  .start-ride-btn-container:active {
    box-shadow: 0px 0px 10px 0px #FECC00;
  }
}

.start-ride-btn-text-value {
  display: flex;
  align-self: center;
  align-content: center;
}

.start-ride-btn-img-value {
  display: flex;
  align-self: center;
  align-content: center;
  width: 14px;
  height: 14px;
  background-size: cover;
}
